<template>
  <v-container grid-list-lg fluid>
    <v-card outlined>
      <v-toolbar flat dense color="grey lighten-3">
        <v-toolbar-title>
          <v-layout class="pa-0 ma-0" row>
            <h1 class="subtitle-1">Statistics</h1>
          </v-layout>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <DateTimePicker
          defaultRange="since30DaysAgo"
          :range="true"
          v-model="dateTime"
          @submit="submit"
        />
      </v-toolbar>
      <!-- <ModelTitle :showBackButton="false" title="Statistics" /> -->
      <Product
          :hideCost="true"
          :hideProfit="true"
          :CustomerId="getCustomer.id"
          :start="dateTime.start"
          :end="dateTime.end"
          :reset="reset"
        ></Product>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
const Product = () => import("@/modules/ReportView/GlobalReport/Product.vue");

const DateTimePicker = () => import("@/components/DateTimePicker");

// import PaymentPie from "@/modules/Customer/Payment/Chart/PaymentMethodPie";

export default {
  data() {
    return {
      reset: false,
      dateTime: {},
    };
  },
  name: "customer-statistics",
  computed: {
    ...mapGetters("customer", ["getCustomer"]),
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    submit() {
      this.reset = !this.reset
    }
  },
  components: {
    Product,
    DateTimePicker,
  },
};
</script>
